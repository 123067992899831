import { storableError } from '../util/errors';
import {
  uploadDocumentToGoogleStorageAPI,
  uploadImageToGoogleStorageAPI,
} from '../util/api';
import axios from 'axios';

// ================ Action types ================ //

export const UPLOAD_DOCUMENT_REQUEST =
  'app/googleStorage/UPLOAD_DOCUMENT_REQUEST';
export const UPLOAD_DOCUMENT_SUCCESS =
  'app/googleStorage/UPLOAD_DOCUMENT_SUCCESS';
export const UPLOAD_DOCUMENT_ERROR = 'app/googleStorage/UPLOAD_DOCUMENT_ERROR';

export const UPLOAD_IMAGE_REQUEST = 'app/googleStorage/UPLOAD_IMAGE_REQUEST';
export const UPLOAD_IMAGE_SUCCESS = 'app/googleStorage/UPLOAD_IMAGE_SUCCESS';
export const UPLOAD_IMAGE_ERROR = 'app/googleStorage/UPLOAD_IMAGE_ERROR';

// ================ Reducer ================ //

const initialState = {
  uploadDocumentInProgress: false,
  uploadDocumentError: null,
  uploadImageInProgress: false,
  uploadImageErorr: null,
};

export default function googleStorageReducer(
  state = initialState,
  action = {}
) {
  const { type, payload } = action;
  switch (type) {
    case UPLOAD_DOCUMENT_REQUEST:
      return {
        ...state,
        uploadDocumentInProgress: true,
        uploadDocumentError: null,
      };
    case UPLOAD_DOCUMENT_SUCCESS:
      return {
        ...state,
        uploadDocumentInProgress: false,
        uploadDocumentError: null,
      };
    case UPLOAD_DOCUMENT_ERROR:
      return {
        ...state,
        uploadDocumentInProgress: false,
        uploadDocumentError: payload,
      };

    case UPLOAD_IMAGE_REQUEST:
      return {
        ...state,
        uploadImageInProgress: true,
        uploadImageError: null,
      };
    case UPLOAD_IMAGE_SUCCESS:
      return {
        ...state,
        uploadImageInProgress: false,
        uploadImageError: null,
      };
    case UPLOAD_IMAGE_ERROR:
      return {
        ...state,
        uploadImageInProgress: false,
        uploadImageError: payload,
      };

    default:
      return state;
  }
}

// ================ Action creators ================ //

export const uploadDocumentRequest = () => ({ type: UPLOAD_DOCUMENT_REQUEST });
export const uploadDocumentSuccess = () => ({ type: UPLOAD_DOCUMENT_SUCCESS });
export const uploadDocumentError = error => ({
  type: UPLOAD_DOCUMENT_ERROR,
  error: true,
  payload: error,
});

export const uploadImageRequest = () => ({ type: UPLOAD_IMAGE_REQUEST });
export const uploadImageSuccess = () => ({ type: UPLOAD_IMAGE_SUCCESS });
export const uploadImageError = error => ({
  type: UPLOAD_IMAGE_ERROR,
  error: true,
  payload: error,
});

// ================ Thunks ================ //

export const uploadDocument = document => async (dispatch, getState, sdk) => {
  dispatch(uploadDocumentRequest());

  // We need to create new FormData and
  // append the file to it, because the
  // SDK doesn't support file uploads.
  const formData = new FormData();
  formData.append('document', document);

  const headers = {
    'content-type': 'multipart/form-data',
  };

  try {
    const response = await axios.post(
      uploadDocumentToGoogleStorageAPI,
      formData,
      {
        headers,
      }
    );

    dispatch(uploadDocumentSuccess(response.data));
    return response.data;
  } catch (e) {
    dispatch(uploadDocumentError(storableError(e)));
  }
};

export const uploadImage = image => async (dispatch, getState, sdk) => {
  dispatch(uploadImageRequest());

  // We need to create new FormData and
  // append the image to it, because the
  // SDK doesn't support uploading images
  // outside of listings
  const formData = new FormData();
  formData.append('image', image);

  const headers = {
    'content-type': 'multipart/form-data',
  };

  try {
    const response = await axios.post(uploadImageToGoogleStorageAPI, formData, {
      headers,
    });

    dispatch(uploadImageSuccess(response.data));
    return response.data;
  } catch (e) {
    dispatch(uploadImageError(storableError(e)));
  }
};