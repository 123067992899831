import React from 'react';
import loadable from '@loadable/component';

import getPageDataLoadingAPI from '../containers/pageDataLoadingAPI';
import NotFoundPage from '../containers/NotFoundPage/NotFoundPage';
import PreviewResolverPage from '../containers/PreviewResolverPage/PreviewResolverPage';

// routeConfiguration needs to initialize containers first
// Otherwise, components will import form container eventually and
// at that point css bundling / imports will happen in wrong order.
import { NamedRedirect } from '../components';

const pageDataLoadingAPI = getPageDataLoadingAPI();

const AuthenticationPage = loadable(() => import(/* webpackChunkName: "AuthenticationPage" */ '../containers/AuthenticationPage/AuthenticationPage'));
const CheckoutPage = loadable(() => import(/* webpackChunkName: "CheckoutPage" */ '../containers/CheckoutPage/CheckoutPage'));
const CMSPage = loadable(() => import(/* webpackChunkName: "CMSPage" */ '../containers/CMSPage/CMSPage'));
const ContactDetailsPage = loadable(() => import(/* webpackChunkName: "ContactDetailsPage" */ '../containers/ContactDetailsPage/ContactDetailsPage'));
const EditListingPage = loadable(() => import(/* webpackChunkName: "EditListingPage" */ '../containers/EditListingPage/EditListingPage'));
const EmailVerificationPage = loadable(() => import(/* webpackChunkName: "EmailVerificationPage" */ '../containers/EmailVerificationPage/EmailVerificationPage'));
const InboxPage = loadable(() => import(/* webpackChunkName: "InboxPage" */ '../containers/InboxPage/InboxPage'));
const LandingPage = loadable(() => import(/* webpackChunkName: "LandingPage" */ '../containers/LandingPage/LandingPage'));
const ListingPageCoverPhoto = loadable(() => import(/* webpackChunkName: "ListingPageCoverPhoto" */ /* webpackPrefetch: true */ '../containers/ListingPage/ListingPageCoverPhoto'));
const ListingPageCarousel = loadable(() => import(/* webpackChunkName: "ListingPageCarousel" */ /* webpackPrefetch: true */ '../containers/ListingPage/ListingPageCarousel'));
const ManageListingsPage = loadable(() => import(/* webpackChunkName: "ManageListingsPage" */ '../containers/ManageListingsPage/ManageListingsPage'));
const PasswordChangePage = loadable(() => import(/* webpackChunkName: "PasswordChangePage" */ '../containers/PasswordChangePage/PasswordChangePage'));
const PasswordRecoveryPage = loadable(() => import(/* webpackChunkName: "PasswordRecoveryPage" */ '../containers/PasswordRecoveryPage/PasswordRecoveryPage'));
const PasswordResetPage = loadable(() => import(/* webpackChunkName: "PasswordResetPage" */ '../containers/PasswordResetPage/PasswordResetPage'));
const PaymentMethodsPage = loadable(() => import(/* webpackChunkName: "PaymentMethodsPage" */ '../containers/PaymentMethodsPage/PaymentMethodsPage'));
const PrivacyPolicyPage = loadable(() => import(/* webpackChunkName: "PrivacyPolicyPage" */ '../containers/PrivacyPolicyPage/PrivacyPolicyPage'));
const ProfilePage = loadable(() => import(/* webpackChunkName: "ProfilePage" */ '../containers/ProfilePage/ProfilePage'));
const ProfileSettingsPage = loadable(() => import(/* webpackChunkName: "ProfileSettingsPage" */ '../containers/ProfileSettingsPage/ProfileSettingsPage'));
const SearchPageWithMap = loadable(() => import(/* webpackChunkName: "SearchPageWithMap" */ /* webpackPrefetch: true */  '../containers/SearchPage/SearchPageWithMap'));
const SearchPageWithGrid = loadable(() => import(/* webpackChunkName: "SearchPageWithGrid" */ /* webpackPrefetch: true */  '../containers/SearchPage/SearchPageWithGrid'));
const StripePayoutPage = loadable(() => import(/* webpackChunkName: "StripePayoutPage" */ '../containers/StripePayoutPage/StripePayoutPage'));
const TermsOfServicePage = loadable(() => import(/* webpackChunkName: "TermsOfServicePage" */ '../containers/TermsOfServicePage/TermsOfServicePage'));
const TransactionPage = loadable(() => import(/* webpackChunkName: "TransactionPage" */ '../containers/TransactionPage/TransactionPage'));
const VerificationPage = loadable(() => import(/* webpackChunkName: "VerificationPage" */ '../containers/VerificationPage/VerificationPage'));
const OttawaPage = loadable(() => import(/* webpackChunkName: "OttawaPage" */ '../containers/OttawaPage/OttawaPage'));
const PerthPage = loadable(() => import(/* webpackChunkName: "PerthPage" */ '../containers/PerthPage/PerthPage'));
const PetrieIslandPage = loadable(() => import(/* webpackChunkName: "PetrieIslandPage" */ '../containers/PetrieIslandPage/PetrieIslandPage'));
const WestportPage = loadable(() => import(/* webpackChunkName: "WestportPage" */ '../containers/WestportPage/WestportPage'));
const TradeShowPage = loadable(() => import(/* webpackChunkName: "TradeShowPage" */ '../containers/TradeShowPage/TradeShowPage'));
const KingstonPage = loadable(() => import(/* webpackChunkName: "KingstonPage" */ '../containers/KingstonPage/KingstonPage'));
const DynamicPage = loadable(() => import(/* webpackChunkName: "DynamicPage" */ '../containers/DynamicPage/DynamicPage'));
const BrockvillePage = loadable(() => import(/* webpackChunkName: "BrockvillePage" */ '../containers/BrockvillePage/BrockvillePage'));
const BobslakePage = loadable(() => import(/* webpackChunkName: "BobslakePage" */ '../containers/BobslakePage/BobslakePage'));
const AdminPage = loadable(() => import(/* webpackChunkName: "AdminPage" */ '../containers/AdminPage/AdminPage'));
const KelownaPage = loadable(() => import(/* webpackChunkName: "KelownaPage" */ '../containers/KelownaPage/KelownaPage'));
const EmilyPage = loadable(() => import(/* webpackChunkName: "KelownaPage" */ '../resellers/EmilyPage/EmilyPage'));
const OwenPage = loadable(() => import(/* webpackChunkName: "OwenPage" */ '../resellers/OwenPage/OwenPage'));

// Styleguide helps you to review current components and develop new ones
const StyleguidePage = loadable(() => import(/* webpackChunkName: "StyleguidePage" */ '../containers/StyleguidePage/StyleguidePage'));

export const ACCOUNT_SETTINGS_PAGES = [
  'ContactDetailsPage',
  'PasswordChangePage',
  'StripePayoutPage',
  'PaymentMethodsPage',
];

// https://en.wikipedia.org/wiki/Universally_unique_identifier#Nil_UUID
const draftId = '00000000-0000-0000-0000-000000000000';
const draftSlug = 'draft';

const RedirectToLandingPage = () => <NamedRedirect name="LandingPage" />;

const locations = [
  'Simplified',
  'NoordAruba',
  'KeswickOntarioCanada',
  'BelleRiverOntarioCanada',
  'OttawaOntarioCanada',
  'PrevezaEpirusandWesternMacedoniaGreece',
  'LefkadaWesternGreeceandtheIonianGreece',
  'ManarolaLiguriaItaly',
  'VassenaLombardyItaly',
  'SorrentoCampaniaItaly',
  'PortofinoLiguriaItaly',
  'CancunQuintanaRooMexico',
  'RotterdamSouthHollandNetherlands',
  'UtrechtUtrechtNetherlands',
  'AmsterdamNorthHollandNetherlands',
  'BergenVestlandNorway',
  'SandaneVestlandNorway',
  'QueijasLisbonPortugal',
  'SesimbraSetubalPortugal',
  'BenagilFaroPortugal',
  'CarvoeiroFaroPortugal',
  'PortimãoFaroPortugal',
  'ViladoBispoFaroPortugal',
  'AlbufeiraFaroPortugal',
  'LagoaFaroPortugal',
  'OlhaoFaroPortugal',
  'LagosFaroPortugal',
  'PortimaoFaroPortugal',
  'BudensFaroPortugal',
  'SanJuanPuertoRico',
  'SalinasPuertoRico',
  'LaPargueraPuertoRico',
  'CarolinaPuertoRico',
  'FajardoPuertoRico',
  'PuertoRealPuertoRico',
  'PuertodeAlcudiaBalearicIslandsSpain',
  'LosCristianosCanaryislandsSpain',
  'LAmetlladeMarCataloniaSpain',
  'CiutadelladeMenorcaBalearicIslandsSpain',
  'EsFigueralBalearicIslandsSpain',
  'GranCanariaCanaryislandsSpain',
  'StockholmCentralSwedenSweden',
  'LeissigenSwitzerland',
  'BallycastleNorthernIreland',
  'LlangollenUnitedKingdom',
  'LlangollenWales',
  'HicklingEastofEnglandEngland',
  'LisnarickNorthernIreland',
  'LisnarickUnitedKingdom',
  'MerrittIslandFloridaUSA',
  'KeyWestFloridaUSA',
  'DestinFloridaUSA',
  'FortLauderdaleFloridaUSA',
  'CaptainCookHawaiiUSA',
  'AustinTexasUSA',
  'CummingGeorgiaUSA',
  'SanAntonioTexasUSA',
  'KennethCityFloridaUSA',
  'CharlestonSouthCarolinaUSA',
  'KaaawaHawaiiUSA',
  'PointVentureTexasUSA',
  'SilverSpringsFloridaUSA',
  'AnnaMariaFloridaUSA',
  'NaplesFloridaUSA',
  'KiheiHawaiiUSA',
  'ChincoteagueVirginiaUSA',
  'MarathonFloridaUSA',
  'GulfBreezeFloridaUSA',
  'NewportBeachCaliforniaUSA',
  'FridayHarborWashingtonUSA',
  'TempeArizonaUSA',
  'TraverseCityMichiganUSA',
  'OceanCityMarylandUSA',
  'ClevelandOhioUSA',
  'TierraVierdeFloridaUSA',
  'LosAngelesCaliforniaUSA',
  'JacksonWyomingUSA',
  'NavarreFloridaUSA',
  'TarponSpringsFloridaUSA',
  'HauulaHawaiiUSA',
  'PuntaGordaFloridaUSA',
  'LakeHavasuCityArizonaUSA',
  'KeyLargoFloridaUSA',
  'MesaArizonaUSA',
  'SanDiegoCaliforniaUSA',
  'KahuluiHawaiiUSA',
  'WaileaHawaiiUSA',
  'CapeCoralFloridaUSA',
  'GeorgetownTexasUSA',
  'FlagstaffArizonaUSA',
  'PeoriaArizonaUSA',
  'PoolesvilleMarylandUSA',
  'PawleysIslandSouthCarolinaUSA',
  'BelmontNorthCarolinaUSA',
  'PompanoBeachFloridaUSA',
  'NorthMyrtleBeachSouthCarolinaUSA',
  'KawaihaeHarborHawaiiUSA',
  'ChiloquinOregonUSA',
  'ChincoteagueIslandVirginiaUSA',
  'MilwaukeeWisconsinUSA',
  'HoustonTexasUSA',
  'CrystalRiverFloridaUSA',
  'KailuaHawaiiUSA',
  'BerlinMarylandUSA',
  'OrlandoFloridaUSA',
  'OrangeBeachAlabamaUSA',
  'Kailua-KonaHawaiiUSA',
  'BoulderCityNevadaUSA',
  'SaintPetersburgFloridaUSA',
  'SlidellLouisianaUSA',
  'OxnardCaliforniaUSA',
  'DunnellonFloridaUSA',
  'KemahTexasUSA',
  'PuakoHawaiiUSA',
  'KyleTexasUSA',
  'FortMyersBeachFloridaUSA',
  'SarasotaFloridaUSA',
  'KapaaHawaiiUSA',
  'MiamiFloridaUSA',
  'HendersonvilleTennesseeUSA',
  'MinneapolisMinnesotaUSA',
  'SpringLakeMichiganUSA',
  'TitusvilleFloridaUSA',
  'MakawaoHawaiiUSA',
  'SpicewoodTexasUSA',
  'LahainaHawaiiUSA',
  'ColumbiaSouthCarolinaUSA',
  'EdmondOklahomaUSA',
  'PearlRiverLouisianaUSA',
  'NewportOregonUSA',
  'TahoeCityCaliforniaUSA',
  'AventuraFloridaUSA',
  'AugustaGeorgiaUSA',
  'McClellansvilleSouthCarolinaUSA',
  'CouerdAleneIdahoUSA',
  'MountPleasantSouthCarolinaUSA',
  'DanaPointCaliforniaUSA',
  'HomerAlaskaUSA',
  'PortOrangeFloridaUSA',
  'CocoaBeachFloridaUSA',
  'MiamiBeachFloridaUSA',
  'BuchananVirginiaUSA',
  'LasVegasNevadaUSA',
  'DaniaBeachFloridaUSA',
  'HomosassaFloridaUSA',
  'TampaFloridaUSA',
  'LaconiaNewHampshireUSA',
  'CovingtonVirginiaUSA',
  'KeyBiscayneFloridaUSA',
  'PensacolaFloridaUSA',
  'PostFallsIdahoUSA',
  'JupiterFloridaUSA',
  'KaneoheHawaiiUSA',
  'MelbourneFloridaUSA',
  'SurfCityNorthCarolinaUSA',
  'HiltonHeadSouthCarolinaUSA',
  'SaintAugustineFloridaUSA',
  'GoldBeachOregonUSA',
  'MarcoIslandFloridaUSA',
  'HonoluluHawaiiUSA',
  'LaieHawaiiUSA',
  'TierraVerdeFloridaUSA',
  'RaymondMaineUSA',
  'NewOrleansLouisianaUSA',
  'KetchikanAlaskaUSA',
  'EggHarborWisconsinUSA',
  'NokomisFloridaUSA',
  'NapaCaliforniaUSA',
  'StJohnVirginIslands',
  'StThomasVirginIslands',
];

const routes = locations.map((location) => ({
  path: `/${location.toLowerCase().replace(/\s+/g, '')}`,
  name: location,
  component: DynamicPage,
}));

// NOTE: Most server-side endpoints are prefixed with /api. Requests to those
// endpoints are indended to be handled in the server instead of the browser and
// they will not render the application. So remember to avoid routes starting
// with /api and if you encounter clashing routes see server/index.js if there's
// a conflicting route defined there.

// Our routes are exact by default.
// See behaviour from Routes.js where Route is created.
const routeConfiguration = (layoutConfig) => {
  const SearchPage = layoutConfig.searchPage?.variantType === 'map' 
    ? SearchPageWithMap 
    : SearchPageWithGrid;
  const ListingPage = layoutConfig.listingPage?.variantType === 'carousel' 
    ? ListingPageCarousel 
    : ListingPageCoverPhoto;
  
  return [
    ...routes,
    {
      path: '/',
      name: 'LandingPage',
      component: LandingPage,
      loadData: pageDataLoadingAPI.LandingPage.loadData,
    },
    {
      path: '/p/:pageId',
      name: 'CMSPage',
      component: CMSPage,
      loadData: pageDataLoadingAPI.CMSPage.loadData,
    },
    {
      path: '/s',
      name: 'SearchPage',
      component: SearchPage,
      loadData: pageDataLoadingAPI.SearchPage.loadData,
    },
    {
      path: '/l',
      name: 'ListingBasePage',
      component: RedirectToLandingPage,
    },
    {
      path: '/l/:slug/:id',
      name: 'ListingPage',
      component: ListingPage,
      loadData: pageDataLoadingAPI.ListingPage.loadData,
    },
    
    {
      path: '/l/:slug/:id/checkout',
      name: 'CheckoutPage',
      auth: true,
      component: CheckoutPage,
      setInitialValues: pageDataLoadingAPI.CheckoutPage.setInitialValues,
    },
    {
      path: '/l/:slug/:id/:variant',
      name: 'ListingPageVariant',
      auth: true,
      authPage: 'LoginPage',
      component: ListingPage,
      loadData: pageDataLoadingAPI.ListingPage.loadData,
    },
    {
      path: '/l/new',
      name: 'NewListingPage',
      auth: true,
      component: () => (
        <NamedRedirect
          name="EditListingPage"
          params={{ slug: draftSlug, id: draftId, type: 'new', tab: 'details' }}
        />
      ),
    },
    {
      path: '/l/:slug/:id/:type/:tab',
      name: 'EditListingPage',
      auth: true,
      component: EditListingPage,
      loadData: pageDataLoadingAPI.EditListingPage.loadData,
    },
    {
      path: '/l/:slug/:id/:type/:tab/:returnURLType',
      name: 'EditListingStripeOnboardingPage',
      auth: true,
      component: EditListingPage,
      loadData: pageDataLoadingAPI.EditListingPage.loadData,
    },

    // Canonical path should be after the `/l/new` path since they
    // conflict and `new` is not a valid listing UUID.
    {
      path: '/l/:id',
      name: 'ListingPageCanonical',
      component: ListingPage,
      loadData: pageDataLoadingAPI.ListingPage.loadData,
    },
    {
      path: '/u',
      name: 'ProfileBasePage',
      component: RedirectToLandingPage,
    },
    {
      path: '/u/:id',
      name: 'ProfilePage',
      component: ProfilePage,
      loadData: pageDataLoadingAPI.ProfilePage.loadData,
    },
    {
      path: '/profile-settings',
      name: 'ProfileSettingsPage',
      auth: true,
      authPage: 'LoginPage',
      component: ProfileSettingsPage,
    },
    {
      path: '/verification',
      name: 'VerificationPage',
      auth: true,
      authPage: 'LoginPage',
      component: VerificationPage,
    },
    {
      path: '/waiver/:wrid',
      name: 'WaiverPage',
      auth: true,
      authPage: 'LoginPage',
      component: props => { window.location.href = `https://wetrentals.onlinewaiverpro.com/waiver/34b83ba0-bfa9-11ee-89c5-9b754ba1ae73?mt_id=${props.params?.wrid}`; },
    },
    {
      path: '/waiver',
      name: 'WaiverMainPage',
      auth: false,
      authPage: 'LoginPage',
      component: props => { window.location.href = 'https://wetrentals.onlinewaiverpro.com/waiver/34b83ba0-bfa9-11ee-89c5-9b754ba1ae73?mt_id=NonLoginWaiver'; },
    },
    {
      path: '/boatLicense',
      name: 'BoatLicensePage',
      auth: false,
      authPage: 'LoginPage',
      component: () => { window.location.href = 'https://canadaboatsafety.com?mcid=Id3kxycC'; },
    },
        {
      path: '/intoVideo',
      name: 'intoVideoPage',
      auth: true,
      authPage: 'LoginPage',
      component: () => { window.location.href = 'https://www.youtube.com/watch?v=PXLwFfzckWw'; },
    },
    {
      path: '/boatLicenseUSA',
      name: 'BoatLicenseUSAPage',
      auth: true,
      authPage: 'LoginPage',
      component: () => { window.location.href = 'https://boattests101.com?mcid=M2j4pDir'; },
    },
    {
      path: '/tempLicense/:wrid',
      name: 'TempLicensePage',
      auth: true,
      authPage: 'LoginPage',
      component: props => { window.location.href = `https://wetrentals.onlinewaiverpro.com/waiver/790d5a50-e477-11ee-b4ce-d79abff73e13?mt_id=${props.params?.wrid}`; },
    },
    {
      path: '/tel/:phoneNumber',
      name: 'PhoneOut',
      auth: true,
      authPage: 'LoginPage',
      component: props => { window.location.href = `tel:${props.params?.phoneNumber}`; },
    },
    {
      path: '/customUrl/:url/:wrid',
      name: 'UrlOut',
      auth: true,
      authPage: 'LoginPage',
      component: props => { window.location.href = `https://${props.params?.url}?mt_id=${props.params?.wrid}`; },
    },
    {
      path: '/rent',
      name: 'rentRedirectPage',
      component: () => {  window.open('https://wetrentals.com/p/how-it-works'); },
    },
    {
      path: '/about',
      name: 'aboutRedirectPage',
      component: () => { window.open('https://wetrentals.com/p/about'); },
    },
    {
      path: '/host/facebook',
      name: 'HostFacebookPage',
      component: () => {
        window.open('https://www.facebook.com/groups/wetrentals', '_blank');
      },
    },
    {
      path: '/help',
      name: 'HelpPage',
      component: () => {
        window.open('https://help.wetrentals.com', '_blank');
      },
    },

    // Note: authenticating with IdP (e.g. Facebook) expects that /login path exists
    // so that in the error case users can be redirected back to the LoginPage
    // In case you change this, remember to update the route in server/api/auth/loginWithIdp.js
    {
      path: '/login',
      name: 'LoginPage',
      component: AuthenticationPage,
      extraProps: { tab: 'login' },
    },
    {
      path: '/signup',
      name: 'SignupPage',
      component: AuthenticationPage,
      extraProps: { tab: 'signup' },
      loadData: pageDataLoadingAPI.AuthenticationPage.loadData,
    },
    {
      path: '/signup/:userType',
      name: 'SignupForUserTypePage',
      component: AuthenticationPage,
      extraProps: { tab: 'signup' },
      loadData: pageDataLoadingAPI.AuthenticationPage.loadData,
    },
    {
      path: '/confirm',
      name: 'ConfirmPage',
      component: AuthenticationPage,
      extraProps: { tab: 'confirm' },
      loadData: pageDataLoadingAPI.AuthenticationPage.loadData,
    },
    {
      path: '/recover-password',
      name: 'PasswordRecoveryPage',
      component: PasswordRecoveryPage,
    },
    {
      path: '/inbox',
      name: 'InboxBasePage',
      auth: true,
      authPage: 'LoginPage',
      component: () => <NamedRedirect name="InboxPage" params={{ tab: 'sales' }} />,
    },
    {
      path: '/inbox/:tab',
      name: 'InboxPage',
      auth: true,
      authPage: 'LoginPage',
      component: InboxPage,
      loadData: pageDataLoadingAPI.InboxPage.loadData,
    },
    {
      path: '/order/:id',
      name: 'OrderDetailsPage',
      auth: true,
      authPage: 'LoginPage',
      component: TransactionPage,
      extraProps: { transactionRole: 'customer' },
      loadData: (params, ...rest) =>
        pageDataLoadingAPI.TransactionPage.loadData({ ...params, transactionRole: 'customer' }, ...rest),
      setInitialValues: pageDataLoadingAPI.TransactionPage.setInitialValues,
    },
    {
      path: '/order/:id/details',
      name: 'OrderDetailsPageRedirect',
      auth: true,
      authPage: 'LoginPage',
      component: props => <NamedRedirect name="OrderDetailsPage" params={{ id: props.params?.id }} />,
    },
    {
      path: '/sale/:id',
      name: 'SaleDetailsPage',
      auth: true,
      authPage: 'LoginPage',
      component: TransactionPage,
      extraProps: { transactionRole: 'provider' },
      loadData: pageDataLoadingAPI.TransactionPage.loadData,
    },
    {
      path: '/sale/:id/details',
      name: 'SaleDetailsPageRedirect',
      auth: true,
      authPage: 'LoginPage',
      component: props => <NamedRedirect name="SaleDetailsPage" params={{ id: props.params?.id }} />,
    },
    {
      path: '/listings',
      name: 'ManageListingsPage',
      auth: true,
      authPage: 'LoginPage',
      component: ManageListingsPage,
      loadData: pageDataLoadingAPI.ManageListingsPage.loadData,
    },
    {
      path: '/account',
      name: 'AccountSettingsPage',
      auth: true,
      authPage: 'LoginPage',
      component: () => <NamedRedirect name="ContactDetailsPage" />,
    },
    {
      path: '/account/contact-details',
      name: 'ContactDetailsPage',
      auth: true,
      authPage: 'LoginPage',
      component: ContactDetailsPage,
      loadData: pageDataLoadingAPI.ContactDetailsPage.loadData,
    },
    {
      path: '/account/change-password',
      name: 'PasswordChangePage',
      auth: true,
      authPage: 'LoginPage',
      component: PasswordChangePage,
    },
    {
      path: '/account/payments',
      name: 'StripePayoutPage',
      auth: true,
      authPage: 'LoginPage',
      component: StripePayoutPage,
      loadData: pageDataLoadingAPI.StripePayoutPage.loadData,
    },
    {
      path: '/account/payments/:returnURLType',
      name: 'StripePayoutOnboardingPage',
      auth: true,
      authPage: 'LoginPage',
      component: StripePayoutPage,
      loadData: pageDataLoadingAPI.StripePayoutPage.loadData,
    },
    {
      path: '/account/payment-methods',
      name: 'PaymentMethodsPage',
      auth: true,
      authPage: 'LoginPage',
      component: PaymentMethodsPage,
      loadData: pageDataLoadingAPI.PaymentMethodsPage.loadData,
    },
    {
      path: '/terms-of-service',
      name: 'TermsOfServicePage',
      component: TermsOfServicePage,
      loadData: pageDataLoadingAPI.TermsOfServicePage.loadData,
    },
    {
      path: '/privacy-policy',
      name: 'PrivacyPolicyPage',
      component: PrivacyPolicyPage,
      loadData: pageDataLoadingAPI.PrivacyPolicyPage.loadData,
    },
    {
      path: '/styleguide',
      name: 'Styleguide',
      component: StyleguidePage,
    },
    {
      path: '/styleguide/g/:group',
      name: 'StyleguideGroup',
      component: StyleguidePage,
    },
    {
      path: '/styleguide/c/:component',
      name: 'StyleguideComponent',
      component: StyleguidePage,
    },
    {
      path: '/styleguide/c/:component/:example',
      name: 'StyleguideComponentExample',
      component: StyleguidePage,
    },
    {
      path: '/styleguide/c/:component/:example/raw',
      name: 'StyleguideComponentExampleRaw',
      component: StyleguidePage,
      extraProps: { raw: true },
    },
    {
      path: '/notfound',
      name: 'NotFoundPage',
      component: props => <NotFoundPage {...props} />,
    },
    {
      path: '/p/host',
      name: 'HostPage',
      component: CMSPage,
      loadData: pageDataLoadingAPI.CMSPage.loadData,
    },
    {
      path: '/p/integratons',
      name: 'IntegratePage',
      component: CMSPage,
      loadData: pageDataLoadingAPI.CMSPage.loadData,
    },
    {
      path: '/p/host',
      name: 'HostPage',
      component: CMSPage,
      loadData: pageDataLoadingAPI.CMSPage.loadData,
    },
    {
      path: '/p/list-your-boat',
      name: 'BecomeHost',
      component: CMSPage,
      loadData: pageDataLoadingAPI.CMSPage.loadData,
    },
    {
      path: '/p/how-it-works',
      name: 'HowItWorks',
      component: CMSPage,
      loadData: pageDataLoadingAPI.CMSPage.loadData,
    },
    {
      path: '/p/calculator',
      name: 'CalculatorPage',
      component: CMSPage,
      loadData: pageDataLoadingAPI.CMSPage.loadData,
    },
    {
      path: '/p/host-tools',
      name: 'HostToolsPage',
      component: CMSPage,
      loadData: pageDataLoadingAPI.CMSPage.loadData,
    },
    {
      path: '/p/insurance',
      name: 'InsurancePage',
      component: CMSPage,
      loadData: pageDataLoadingAPI.CMSPage.loadData,
    },

    // Do not change this path!
    //
    // The API expects that the application implements /reset-password endpoint
    {
      path: '/reset-password',
      name: 'PasswordResetPage',
      component: PasswordResetPage ,
    },

    // Do not change this path!
    //
    // The API expects that the application implements /verify-email endpoint
    {
      path: '/verify-email',
      name: 'EmailVerificationPage',
      auth: true,
      authPage: 'LoginPage',
      component: EmailVerificationPage,
      loadData: pageDataLoadingAPI.EmailVerificationPage.loadData,
    },
    // Do not change this path!
    //
    // The API expects that the application implements /preview endpoint
    {
      path: '/preview',
      name: 'PreviewResolverPage',
      component: PreviewResolverPage ,
    },

  ////// Custom pages
  {
    path: '/Ottawa',
    name: 'OttawaPage',
    component: OttawaPage,
  },  
  {
    path: '/Perth',
    name: 'PerthPage',
    component: PerthPage,
  },  
  {
    path: '/petrieisland',
    name: 'PetrieIslandPage',
    component: PetrieIslandPage,
  }, 
  {
    path: '/westport',
    name: 'WestportPage',
    component: WestportPage,
  }, 
  {
    path: '/show',
    name: 'TradeShowPage',
    component: TradeShowPage,
  }, 
  {
    path: '/kingston',
    name: 'KingstonPage',
    component: KingstonPage,
  }, 
  {
    path: '/brockville',
    name: 'BrockvillePage',
    component: BrockvillePage,
  }, 
  {
    path: '/bobslake',
    name: 'BobslakePage',
    component: BobslakePage,
  }, 
  {
    path: '/admin',
    name: 'AdminPage',
    component: AdminPage,
  }, 
  {
    path: '/kelowna',
    name: 'KelownaPage',
    component: KelownaPage,
  },
  {
    path: '/emily',
    name: 'EmilyPage',
    component: EmilyPage,
  },
  {
    path: '/owen',
    name: 'OwenPage',
    component: OwenPage,
  },
  ];
};

export default routeConfiguration;
